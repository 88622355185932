function Navbar() {
  return (
    <nav className="navbar is-transparent" role="navigation">
      <div id="navbarItems">
        <div className="navbar-start">
          <a className="navbar-item" href="#home">
            <img alt="initial Q" src="/favicon-32x32.png"></img>
          </a>
          <a className="navbar-item" href="#portfolio">
            Portfolio
          </a>
          <a className="navbar-item" href="#contact">
            Contact
          </a>
        </div>
      </div>
    </nav>
  );
}
export default Navbar;
