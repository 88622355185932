function Card(props) {
  return (
    <div className="card">
      <div className="card-image">
        <figure className="image is-4by4">
          <img src={props.image} alt={props.title}></img>
        </figure>
      </div>
      <div className="card-content">
        <div className="media">
          <div className="media-content">
            <a
              className="title is-4 a-light-bg"
              href={props.link}
              target="_blank"
              rel="noreferrer"
            >
              {props.title}
            </a>
          </div>
        </div>
        <div className="content">{props.content}</div>
      </div>
    </div>
  );
}
export default Card;
