import Navbar from "./Navbar";
import Hero from "./Hero/Hero.js";
import Portfolio from "./Portfolio/Portfolio.js";
import Footer from "./Footer/Footer.js";

function App() {
  return (
    <div className="App">
      <Navbar />
      <Hero />
      <Portfolio />
      <Footer />
    </div>
  );
}

export default App;
