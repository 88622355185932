import "./footer.sass";

function Footer() {
  return (
    <div className="footer" id="contact">
      <div className="contact">
        <h3 className="contact-title">Contact</h3>
        <div className="icons">
          <a className="a-light-bg" href="https://github.com/griffique">
            <i className="fab fa-github-square"></i>
          </a>
          <a
            className="a-light-bg"
            href="https://www.linkedin.com/in/quinn-griffin-9955711b8/"
          >
            <i className="fab fa-linkedin"></i>
          </a>
        </div>
        <a
          className="a-light-bg"
          href="mailto:purlbot@protonmail.com"
          title="email-link"
        >
          purlbot@protonmail.com
        </a>
      </div>
      <h4 className="coded-by">
        <a
          className="a-light-bg"
          href="https://github.com/griffique"
          title="github-link"
        >
          Designed and Coded
        </a>{" "}
        by Quinn Griffin
      </h4>
    </div>
  );
}
export default Footer;
