import "./hero.sass";
import img from "../hero.png";

function App() {
  return (
    <div className="hero" id="home">
      <img src={img} alt="Quinn Griffin, Learning Experience Designer and Developer"></img>
      <p className="hero-textbox">
      I'm an experienced eLearning designer/developer and former professor with a robust skill set in 
      instructional design, UX, development, and project management. With a passion for translating 
      technical knowledge into engaging digital experiences, I excel at creating innovative and 
      effective online learning solutions. See my <a className="a-light-bg" href="#portfolio">portfolio</a> and <a className="a-light-bg" href="https://docs.google.com/document/d/e/2PACX-1vS4BlppIsRMx14zmu4zZ3mwOuIdrw-sOaRAp5rx1e79JqPxQAcubKKIcXxPRbDYXFSwzwGJEMYHd8pm/pub" target="blank" rel="noreferrer">resume</a> for more.
      </p>
    </div>
  );
}

export default App;
