import "./portfolio.sass";

import imgx from "./images/om-logo.png";
// import img0 from "./images/payola.png";/
// import img2 from "./images/hawkins-machine.png";
import img3 from "./images/purlbot.png";
import img4 from "./images/react-dictionary.png";
import img5 from "./images/react-weather.png";
import img6 from "./images/vanilla-weather.png";
// import img7 from "./images/pets.jpg";
import img8 from "./images/om.jpg";

import Card from "./Card.js";

function Portfolio() {
  const portfolioItems = [
    {
      title: "OM Meditation App",
      image: img8,
      content: "A meditation website for meditators of all levels.",
      link: "https://grand-sorbet-79cda8.netlify.app/",
    },
    {
      title: "OM App Case Study",
      image: imgx,
      content:
        "Case study in fulfillment of the Google UX final project.",
      link: "https://drive.google.com/file/d/1XS-TI3psXzcKApGHO0ZxCJHq5QIRIIEr/view?usp=sharing",
    },
    // {
    //   title: "Payola App Case Study",
    //   image: img0,
    //   content: "An album tracking app prototyped in Figma and Adobe XD.",
    //   link: "https://docs.google.com/presentation/d/e/2PACX-1vRvGTr0CD9ro0j6wOad6TIq4sumFWxBr2TYajZ6Hqr7qerCUYWoBZh-uP_RXNqnaODhNX2T32PzRZyK/pub?start=false&loop=false&delayms=3000&slide=id.p",
    // },
    // {
    //   title: "Hawkins Machine",
    //   image: img2,
    //   content:
    //     "A single-page site for a local machine shop with MailChimp integration.",
    //   link: "https://www.hawkins-machine.com",
    // },
    {
      title: "PurlBot Knitting Pattern Generator",
      image: img3,
      content:
        "A knitting pattern generator built with Python, Flask, MongoDB and a React frontend.",
      link: "https://purlbot.onrender.com/",
    },
    {
      title: "React Dictionary",
      image: img4,
      content: "A word lookup tool with multiple API integration.",
      link: "https://epic-montalcini-b8e09a.netlify.app/",
    },
    {
      title: "React Weather",
      image: img5,
      content: "Final project for SheCodes React.",
      link: "https://angry-austin-6b0a37.netlify.app/",
    },
    {
      title: "Vanilla JS Weather",
      image: img6,
      content: "Final Project for SheCodes Plus.",
      link: "https://vigorous-clarke-7b1ab7.netlify.app/",
    },
    // {
    //   title: "Pet Pedicures",
    //   image: img7,
    //   content: "A landing page for a KY-based pet business.",
    //   link: "https://sunny-basbousa-cc6253.netlify.app/",
    // },
  ];

  return (
    <div className="portfolio" id="portfolio">
      <h2 className="portfolio-title">Development Portfolio</h2>
      <div className="portfolio-grid">
        {portfolioItems.map((element) => (
          <Card
            key={element.title}
            title={element.title}
            content={element.content}
            link={element.link}
            image={element.image}
          />
        ))}
      </div>
    </div>
  );
}
export default Portfolio;
